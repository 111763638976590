import {IRoleQuery, IRole} from "@/types/system/role.d.ts";
import {IResult} from "@/types/share.d.ts";
import systemApi from "@/api/systemApi";
import request from '@/libs/request/index'

declare interface IUseRole{
  getAgentRoleList: (params: IRoleQuery) => Promise<IResult>;
  getAgentRoleAll: () => Promise<IResult>;
  deleteAgentRole: (id: string | number) => Promise<IResult>;
  getAgentRoleDetail: (id: string | number) => Promise<IResult>;
  editAgentRole: (params: IRole) => Promise<IResult>;
  saveAgentRole: (params: IRole) => Promise<IResult>;
}
function useRole(): IUseRole{

  //分页查询角色信息
  function getAgentRoleList( params: IRoleQuery ): Promise<IResult>{
    return request.jsonPost(systemApi.agentRoleQuery, params)
  }

  //查询角色信息
  function getAgentRoleAll(): Promise<IResult>{
    return request.get(systemApi.agentRoleQueryAll)
  }

  //删除角色
  function deleteAgentRole(id: string | number): Promise<IResult>{
    return request.del(systemApi.agentRoleDelete+id)
  }

  //获取角色详情
  function getAgentRoleDetail(id: string | number): Promise<IResult>{
    return  request.get(systemApi.agentRoleDetail+id)
  }

  //修改角色
  function editAgentRole(params: IRole): Promise<IResult>{
    return request.put(systemApi.agentRoleUpdate, params)
  }

  //创建角色
  function saveAgentRole(params: IRole): Promise<IResult>{
    return request.jsonPost(systemApi.agentRoleUpdate, params)
  }

  return {
    getAgentRoleList,
    getAgentRoleAll,
    deleteAgentRole,
    getAgentRoleDetail,
    editAgentRole,
    saveAgentRole
  }
}

export {
  IUseRole,
  useRole
}
